<template>
  <div>
    <b-card no-body class="mb-0">
      <b-table
        :items="items"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          {{ `${data.item.first_Name__c} ${data.item.last_Name__c}` }}
        </template>
        <template #cell(amount)="data">
          {{ `£${data.item.transaction_Amount__c}` }}
        </template>

        <template #cell(date)="data">
          {{
            new Date(data.item.transaction_Date__c).toLocaleDateString(
              "en-UK",
              {
                year: "numeric",
                day: "numeric",
                month: "short",
              }
            )
          }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      items: [],
      fields: [
        { label: "name", key: "name" },
        { label: "email", key: "email__c" },
        { label: "country", key: "country__c" },
        { label: "amount", key: "amount" },
        { label: "date", key: "date" },
        { label: "type", key: "donation_Type" },
        { label: "tansaction type", key: "transaction_Type__c" },
      ],
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
    }
  },
  methods: {
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Transactions",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style></style>
